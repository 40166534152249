import DenseTable from "../components/tables/DenseTable";
import React, {useEffect, useState} from "react";
import {VehicleDto} from "../api/S2.0-500/dtos/vehicleDto";
import {withData} from "../utils/hoc/withData";
import VehicleAPI from "../api/S2.0-500/vehicles/vehicleAPI";
import {useAuth} from "../utils/hooks/auth";
import {Button, Card, CardActions, CardContent, makeStyles} from "@material-ui/core";
import moment from "moment";
import VehicleNewCard from "../components/cards/vehicleNewCard";
import CustomButton from "../components/shared/buttons/button";
import {useDataManager} from "../utils/hooks/data";
import {withNamespaces} from "react-i18next";
import {withSnackbar} from "../utils/hoc/withSnackbar";
import {DATE_FORMAT} from "../constants/utils";
import Usage from "../constants/usage";
import {HoursDto} from "../api/S2.0-500/dtos/hoursDto";
import Led from "../components/led/led";
import {Modal, ModalOkCancel} from "../components/shared/modal";
import {KeyboardDatePicker} from "@material-ui/pickers";

const useStyles = makeStyles({
	button: {
		background: '#FFCC00',
		borderRadius: 3,
		border: 0,
		fontSize: 12,
		color: 'black',
		height: 36,
		padding: '0 30px',
	}
});

function Vehicles(props) {
	const classes = useStyles();
	const { snackbarShowMessage } = props;

	const auth = useAuth();
	const data = useDataManager();
	const { vehicles } = data;
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);

	const headers = ['status'].concat(Object.keys(new VehicleDto()));
	const ignored = ['id'];
	const translations = {
		'status': props.t("vehicles_vehicle_status"),
		'chassisNo': props.t("vehicles_vehicle_chassis_no"),
		'resetDate': props.t("vehicles_vehicle_reset_date")
	};

	const onSuccess = result => {
		snackbarShowMessage(props.t("api_operation_succeed"), "success");
		setOpen(null); // Hotfix to reload the page.
		setOpen(false);
	};
	const onFailure = failure => {
		console.error(failure);
		snackbarShowMessage(props.t("api_operation_failed"), "error");
	}

	// Fetch data
	useEffect(() => {
		data.fetch();
	}, [open]);

	// Update data when fetch is finished
	useEffect(async () => {
		if(!Array.isArray(vehicles) || vehicles.length === 0) return;

		// Validate vehicles
		const newRows = [];
		for(const vehicle of vehicles) {
			const resetDate = vehicle.resetDate || (
				<Button
					classes={{ root: classes.button }}
					title={props.t("vehicles_vehicle_reset")}
					onClick={() => openResetModal(vehicle)}>
					{props.t("vehicles_vehicle_reset")}
				</Button>
			);
			try {
				const hoursDto:HoursDto = await VehicleAPI.getVehicleHours(auth, vehicle.chassisNo);
				const color = Usage.getColor(hoursDto?.usage);
				const tooltip = Usage.getDescription(hoursDto?.usage, props.t);
				newRows.push(
					Object.assign(
						{},
						vehicle,
						{
							resetDate: resetDate,
							status: <Led color={color} tooltip={tooltip} />
						}
					)
				);
			} catch (e) {
				console.error(e);
			}
		}

		// Sort by chassisNo
		const sortedRows = newRows.sort((a, b) => {
			if(a.chassisNo < b.chassisNo) { return -1; }
			if(a.chassisNo > b.chassisNo) { return 1; }
			return 0;
		})

		// Update
		setRows(sortedRows);
	}, [vehicles]);

	function resetVehicle(chassisNo) {
		VehicleAPI.resetVehicle(auth, chassisNo, resetDate)
			.then(onSuccess)
			.catch(onFailure);
	}

	// Components
	const [openModal, setOpenModal] = useState(false);
	const [resetDate, setResetDate] = useState(null);
	const handleResetDateChange = (date) => {
		setResetDate(moment(date).toISOString());
	};

	function openResetModal() {
		setOpenModal(true);
	}

	function closeModal() {
		setOpenModal(false);
	}

	// Render
	return (
		<React.Fragment>
			<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
				<CardContent className={"custom-card-content"}>
					<DenseTable
						headers={headers}
						items={rows}
						ignored={ignored}
						translations={translations}
						onRowClick={item => data.setChassisNo(item.chassisNo)}
					/>

				</CardContent>
				<CardActions>
					<CustomButton className={"btn-card"} text={"New vehicle"} onClick={() => setOpen(true)} />
				</CardActions>
			</Card>
			{open && (
				<VehicleNewCard
					vehicles={vehicles}
					onSuccess={onSuccess}
					onFailure={onFailure}
				/>
			)}
			{openModal && (
				<ModalOkCancel
					title={props.t("modal_vehicles_reset_date_title")}
					cancelTitle={props.t("modal_abort")}
					okTitle={props.t("modal_confirm")}
					onCancel={() => closeModal()}
					onOk={() => {
						closeModal();
						resetVehicle(data.chassisNo);
					}}
				>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						className={"form-item__input"}
						margin="normal"
						id="date-picker-dialog"
						format={DATE_FORMAT}
						value={resetDate}
						onChange={handleResetDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</ModalOkCancel>
			)}
		</React.Fragment>
	);
}

export default withNamespaces()(withSnackbar(withData(Vehicles)));
