import React, {Fragment, useEffect, useState} from "react";
import {
	HashRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import Header from "./components/header/header";

// Styles
import '../styles/main.scss';
import {modules} from "./constants/modules";
import {withAuth} from "./utils/hoc/withAuth";
import 'react-pro-sidebar/dist/css/styles.css';
import Sidebar from "./components/sidebar/sidebar";
import {ProvideDataManager} from "./utils/hooks/data";
import {useAuth} from "./utils/hooks/auth";

function Main(props) {
	const auth = useAuth();
	useEffect(() => {
		auth.autologin();
	}, []);

	if(auth.isAuthenticated) {
		return (
			<Fragment>
				<ProvideDataManager>
					<Sidebar {...props} />
					<div className="app-module">
						<Header {...props} />
						<div className="container">
							<Switch>
								{Object.values(modules.app.routes).map((route, id) => (
										<Route
											exact={true}
											key={id}
											path={route.path}
											render={routeProps => (
												<route.component {...Object.assign({}, props, routeProps)} />
											)}
										/>
									))
								}
								<Redirect to={modules.app.redirect} />
							</Switch>
						</div>
					</div>
				</ProvideDataManager>
			</Fragment>
		)
	} else {
		return (
			<Switch>
				{Object.values(modules.login.routes).map((route, id) => (
					<Route
						exact={true}
						key={id}
						path={route.path}
						render={routeProps => (
							<route.component {...Object.assign({}, props, routeProps)} />
						)}
					/>
				))}
				<Redirect to={modules.login.redirect} />
			</Switch>
		)
	}
}

function App(props) {
	const [screenSize, setScreenSize] = useState(window.innerWidth);
	// Catch resize events
	useEffect(() => {
		function onResize() {
			setScreenSize(window.innerWidth)
		}
		window.addEventListener('resize', onResize);
		return function cleanup() {
			window.removeEventListener('resize', onResize)
		}
	});

	// Render
	return (
		<Router>
			<div className="app">
				<div className="app-container">
					<Main {...Object.assign({}, props, { screenSize })} />
				</div>
			</div>
		</Router>
	);
}

export default withAuth(App);
