import React, {useEffect, useState} from "react";
import FormInputLabel from "./formInputLabel";
import {bool, func, string} from "prop-types";
import {Checkbox} from "@material-ui/core";

function FormCheckbox(props) {
	const {
		label,
		tooltip,
		value,
		defaultValue,
		onChange,
		disabled
	} = props;

	const [checked, setChecked] = useState(defaultValue);

	useEffect(() => {
		setChecked(value);
	}, [value]);

	const handleChange = (event) => {
		setChecked(event.target.checked);
		onChange(event.target.checked);
	};

	return (
		<div className={"form-item"}>
			{label && <FormInputLabel label={label} tooltip={tooltip} />}
			<Checkbox
				className={"form-item__input"}
				checked={checked}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
}


FormCheckbox.defaultProps = {
	defaultValue: true,
	onChange: () => {},
	disabled: false,
}

FormCheckbox.propTypes = {
	label: string,
	tooltip: string,
	value: bool,
	defaultValue: bool,
	onChange: func,
	disabled: bool
}

export default FormCheckbox;
