const { REACT_APP_BOSCHUNG_SERVICES_API_URL } = process.env;
const { REACT_APP_BOSCHUNG_BADMIN_URL } = process.env;

// --- bAdmin --- //
const URL_BADMIN_ROOT_ORGANISATION = REACT_APP_BOSCHUNG_BADMIN_URL + "organisation/";
const URL_BADMIN_ROOT_BMOVES = REACT_APP_BOSCHUNG_BADMIN_URL + "bmoves/";

export const URL_LOGIN_SIGNUP = REACT_APP_BOSCHUNG_BADMIN_URL + "signup?subscriptionPlan=6";
export const URL_LOGIN_RECOVER = REACT_APP_BOSCHUNG_BADMIN_URL + "recover";

// --- Services --- //
export const URL_SERVICES_COMMON_USER = REACT_APP_BOSCHUNG_SERVICES_API_URL + '/common/user/v1/';
export const URL_SERVICES_COMMON_AUTH = REACT_APP_BOSCHUNG_SERVICES_API_URL + '/common/auth/v1/';
export const URL_SERVICES_S20500 = (process.env.NODE_ENV === "development" ? 'http://127.0.0.1:8080' : REACT_APP_BOSCHUNG_SERVICES_API_URL) + '/s20500';

// --- S2.0 - 500 --- //
export const URL_SERVICES_S20500_FORMULAS = URL_SERVICES_S20500 + "/formulas";
export const URL_SERVICES_S20500_VEHICLES = URL_SERVICES_S20500 + "/vehicles";
export const URL_SERVICES_S20500_INTERVENTIONS = (chassisNo) => URL_SERVICES_S20500_VEHICLES + `/${chassisNo}/interventions`;
export const URL_SERVICES_S20500_CONTRACTS = (chassisNo) => URL_SERVICES_S20500_VEHICLES + `/${chassisNo}/contracts`;
export const URL_SERVICES_S20500_RENEWALS = (chassisNo, contractUUID) => URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/renewals`;
export const URL_SERVICES_S20500_SUBSCRIPTIONS = (chassisNo, contractUUID) => URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/subscriptions`;

