const colors = {
	UNTOUCHED: 'linear-gradient(45deg, #ababab 45%, #FFFFFF 95%)',
	AVAILABLE: 'linear-gradient(45deg, #00e000 45%, #FFFFFF 95%)',
	WORRYING: 'linear-gradient(45deg, #FFCC00 45%, #FFFFFF 95%)',
	CRITICAL: 'linear-gradient(45deg, #FF0000 45%, #FFFFFF 95%)',
	EXHAUSTED: 'linear-gradient(45deg, #5d1818 45%, #FFFFFF 95%)',
	UNKNOWN: 'linear-gradient(45deg, #525252 45%, #FFFFFF 95%)'
}

const descriptions = {
	UNTOUCHED: 'usage_untouched_tooltip',
	AVAILABLE: 'usage_available_tooltip',
	WORRYING: 'usage_worrying_tooltip',
	CRITICAL: 'usage_critical_tooltip',
	EXHAUSTED: 'usage_exhausted_tooltip',
	UNKNOWN: 'usage_unknown_tooltip'
}

export default class Usage {

	/**
	 * Return the color for the given usage.
	 * @param usage to retrieve.
	 * @return {string}
	 */
	static getColor(usage) {
		return colors[usage] || colors.UNKNOWN;
	}

	/**
	 * Return the description for the given usage.
	 * @param usage to retrieve.
	 * @param t optional, used to translate.
	 * @return {string}
	 */
	static getDescription(usage, t?) {
		const key = descriptions[usage] || descriptions.UNKNOWN;
		return t ? t(key) : key;
	}
}
