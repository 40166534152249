import {CustomTooltip} from "../shared/tooltip/tooltip";
import React from "react";
import {string} from "prop-types";

function FormInputLabel(props) {
	const {
		label,
		tooltip,
	} = props;

	return (
		<span className={"form-item__label"}>
			{label}
			{tooltip && <CustomTooltip title={tooltip} />}
		</span>
	);
}

FormInputLabel.defaultProps = {
	label: '',
	tooltip: null,
}

FormInputLabel.propTypes = {
	label: string,
	tooltip: string,
}

export default (FormInputLabel);
