import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./app/App";
import reportWebVitals from './reportWebVitals';
import I18nManager from "./app/utils/i18n/i18n-manager";
import {ProvideAuth} from "./app/utils/hooks/auth";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

ReactDOM.render(
    <I18nManager>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ProvideAuth>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </ProvideAuth>
        </MuiPickersUtilsProvider>
    </I18nManager>,
    document.querySelector('.app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
