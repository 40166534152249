export async function sleep(milliseconds) {
	await new Promise(r => setTimeout(r, milliseconds));
}

export function isNullOrUndefined(o) {
	return o === undefined || o === null;
}

export function isIterable(array) {
	return !isNullOrUndefined(array) && Array.isArray(array) && array.length > 0;
}
