export const EMPTY_STRING = '';

export function isVowel(c) {
	return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase()) !== -1;
}

/**
 * Return a string with the first letter capitalized.
 * @param {string} string
 */
export const capitalizeFirstLetter = (string: string) => {
	if (typeof string !== 'string') {
		throw new TypeError('capitalizeFirstLetter() : the param must be a string');
	}
	if (!string) {
		return '';
	}
	return string[0].toUpperCase() + string.slice(1);
};

export function getUpperCase(s) {
	const chars = [];
	let i=0;
	let character='';
	while (i < s.length){
		character = s.charAt(i);
		if (character === character.toUpperCase()) {
			chars.push(character);
		}
		i++;
	}
	return chars;
}

/**
 * Compress a string using vowels.
 * @param s {String} to compress.
 * @param a {String} to use to fill the gap.
 * @param max {Number} size desired.
 * @return {string} the compressed string to the given max size.
 */
export function compressString(s, a, max) {
	const input = s.trim().replace(/\s/g, '');
	const nbOfUpperCaseLetter = getUpperCase(input).length;
	const availableLowerCaseCount = max - nbOfUpperCaseLetter;
	const lowerCaseLetterPerUpperCaseLetter = Math.floor(availableLowerCaseCount / nbOfUpperCaseLetter);

	const chars = [];
	let index=0;
	let character='';
	let lastWasUpperCase = false;
	let nbOfLowerCaseSinceLastUpperCase = 0;
	let nbOfUpperCaseLetterExplored = 0;
	let nbOfLowerCaseLetterWritten = 0;
	while (index < input.length){
		character = input.charAt(index);
		if (character === character.toUpperCase()) {
			chars.push(character);
			lastWasUpperCase = true;
			nbOfLowerCaseSinceLastUpperCase = 0;
			nbOfUpperCaseLetterExplored++;
		} else {
			if(input.length < max || (
				!isVowel(character) &&
				nbOfLowerCaseLetterWritten < availableLowerCaseCount &&
				(
					lastWasUpperCase ||
					nbOfUpperCaseLetterExplored === nbOfUpperCaseLetter ||
					(lowerCaseLetterPerUpperCaseLetter > 0 && nbOfLowerCaseSinceLastUpperCase < lowerCaseLetterPerUpperCaseLetter)
				)
			)) {
				chars.push(character);
				nbOfLowerCaseSinceLastUpperCase++;
				nbOfLowerCaseLetterWritten++;
			}
			lastWasUpperCase = false;
		}
		index++;
	}

	// Create the TAG
	let tag = chars.join('');
	while(tag.length < max) {
		tag = tag + a;
	}
	return tag.substring(0, max);
}
