import React, {createContext, useContext, useEffect, useState} from "react";
import {useAuth} from "./auth";
import {VehicleDto} from "../../api/S2.0-500/dtos/vehicleDto";
import {useStateWithLocalStorage, useStateWithSessionStorage} from "./storage";
import {FormulaDto} from "../../api/S2.0-500/dtos/formulaDto";
import FormulaAPI from "../../api/S2.0-500/formulas/formulaAPI";
import VehicleAPI from "../../api/S2.0-500/vehicles/vehicleAPI";
import {isNullOrUndefined} from "../toolbox";
import {isChassisNoValid} from "../../constants/utils";

const DataContext = createContext(undefined);

/* --- KEYS --- */
const KLS_DATA_FORMULAS = 'kls_data_formulas';
const KLS_DATA_VEHICLES = 'kls_data_vehicles';
const KSS_DATA_VEHICLE_CHASSIS_NO = 'kls_data_vehicle_chassis_no';

export function ProvideDataManager({ children }) {
	const dataManager = useProvideDataManager();
	return <DataContext.Provider value={dataManager}>{children}</DataContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useDataManager = () => {
	return useContext(DataContext);
};

// Provider hook that creates auth object and handles state
function useProvideDataManager() {
	const auth = useAuth();

	const [formulas:Array<FormulaDto>, setFormulas] = useStateWithLocalStorage(KLS_DATA_FORMULAS, []);
	const [vehicles:Array<VehicleDto>, setVehicles] = useStateWithLocalStorage(KLS_DATA_VEHICLES, []);

	// --- Live data --- //
	const [chassisNo, setChassisNo] = useStateWithSessionStorage(KSS_DATA_VEHICLE_CHASSIS_NO, '');
	const [isValid, setIsValid] = useState(true);
	const [vehicle:VehicleDto, setVehicle] = useState(null);

	function fetch() {
		FormulaAPI.getFormulas(auth).then(setFormulas);
		VehicleAPI.getVehicles(auth).then(setVehicles);
	}

	// --- Boot load --- //
	useEffect(() => {
		fetch();
	}, [auth]);

	useEffect(() => {
		if(isChassisNoValid(chassisNo)) {
			setIsValid(true);
			selectVehicle(chassisNo);
		} else {
			setIsValid(false);
			selectVehicle(null);
		}
	}, [chassisNo, formulas, vehicles]);

	function selectVehicle(chassisNo) {
		// Update vehicle
		setVehicle(Array.isArray(vehicles) && !isNullOrUndefined(chassisNo) ?
			vehicles.find(v => v.chassisNo === chassisNo) : null
		);
	}

	return {
		fetch,
		setChassisNo,
		chassisNo,
		isValid,
		formulas,
		vehicles,
		vehicle,
	}
}
