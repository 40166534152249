import {request} from "../../api";
import {URL_SERVICES_S20500_FORMULAS} from "../../../constants/urls";
import {HTTP} from "../../../constants/http";

export default {

	/**
	 * Returns all formula.
	 * @param auth provided by useAuth or withAuth.
	 * @return {Promise<Array<FormulaDto>>}
	 */
	async getFormulas(auth) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_FORMULAS,
			null
		);
	},

	/**
	 * Returns a formula.
	 * @param auth provided by useAuth or withAuth.
	 * @param identifier {number} of the formula.
	 * @return {Promise<FormulaDto>}
	 */
	async getFormula(auth, identifier) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_FORMULAS + `/${identifier}`,
			null
		);
	},
}
