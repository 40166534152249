import {Card, CardContent, Link} from "@material-ui/core";
import React, {useState} from "react";
import {modules} from "../../constants/modules";
import {withNamespaces} from "react-i18next";
import {Redirect} from "react-router-dom";

function NoData(props) {
	const { t } = props;
	const regex = /(?:[{|}])/;
	const message = t('nodata_detail').split(regex).map(chunk => {
		let node = chunk;
		if(chunk === 'link') {
			node = (
				<Link to={modules.app.redirect} onClick={() => setHotfix(true)}>
					{t('redirect_to_vehicles')}
				</Link>
			);
		}
		return <span style={{margin: 4}}>{node}</span>;
	});

	// Hotfix since links are not working as they should…
	const [hotfix, setHotfix] = useState(false);
	return (
		<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
			<CardContent className={"custom-card-content"}>
				<div className={"flex-column flex-container__center"}>
					<h1 className={"text-big"}>
						{t("nodata_title")}
					</h1>
					<h2 className={"flex-row text-big"}>
						{message}
					</h2>
					{hotfix && <Redirect to={modules.app.redirect}/>}
				</div>
			</CardContent>
		</Card>
	);
}

export default withNamespaces()(NoData);
