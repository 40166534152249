import {KeyboardDatePicker} from "@material-ui/pickers";
import React, {useEffect, useState} from "react";
import FormInputLabel from "./formInputLabel";
import {DATE_FORMAT} from "../../constants/utils";
import moment from "moment";
import {bool, func, object, string} from "prop-types";

function FormDatePicker(props) {

	const {
		label,
		tooltip,
		value,
		defaultValue,
		onChange,
		disabled
	} = props;

	const [date, setDate] = useState(defaultValue);

	useEffect(() => {
		setDate(value);
	}, [value]);

	const handleDateChange = (date) => {
		const newValue = moment(date).toISOString();
		setDate(newValue);
		onChange(newValue);
	};

	return (
		<div className={"form-item"}>
			{label && <FormInputLabel label={label} tooltip={tooltip} />}
			<KeyboardDatePicker
				disableToolbar
				variant="inline"
				className={"form-item__input"}
				margin="normal"
				format={DATE_FORMAT}
				value={date}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				disabled={disabled}
			/>
		</div>
	);
}


FormDatePicker.defaultProps = {
	defaultValue: Date.now(),
	onChange: () => {},
	disabled: false,
}

FormDatePicker.propTypes = {
	label: string,
	tooltip: string,
	value: object,
	defaultValue: object,
	onChange: func,
	disabled: bool
}

export default FormDatePicker;
