import {MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import {array, bool, func, object, string} from "prop-types";
import {withNamespaces} from "react-i18next";
import FormInputLabel from "./formInputLabel";

function FormSelect(props) {

	const {
		label,
		tooltip,
		defaultValue,
		items,
		onChange,
		renderFnc,
		sortFnc,
		disabled
	} = props;

	const [value, setValue] = useState(defaultValue);
	const handleChange = (event) => {
		setValue(event.target.value);
		onChange(event.target.value);
	};

	return (
		<div className={"form-item"}>
			{label && <FormInputLabel label={label} tooltip={tooltip} />}
			<Select
				className={"form-item__input"}
				value={value}
				onChange={handleChange}
				disabled={disabled}
			>
				{Array.isArray(items) && Array.length > 0 ? (
					items.sort(sortFnc).map(i => <MenuItem value={i}>{renderFnc(i)}</MenuItem>)
				) : (
					<MenuItem value={null}>
						<em>{props.t("none")}</em>
					</MenuItem>
				)}
			</Select>
		</div>
	);
}

FormSelect.defaultProps = {
	defaultValue: null,
	items: [],
	onChange: () => {},
	renderFnc: (item) => item.toString(),
	sortFnc: () => 0,
	disabled: false
}

FormSelect.propTypes = {
	label: string,
	tooltip: string,
	defaultValue: object,
	items: array,
	onChange: func,
	renderFnc: func,
	sortFnc: func,
	disabled: bool
}

export default withNamespaces()(FormSelect);
