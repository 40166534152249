import React, {useContext, createContext, useState, useEffect, useRef} from "react";
import jwtDecode from "jwt-decode";
import {isNullOrUndefined} from "../toolbox";
import {getTimestamp} from "../date";
import {useStateWithSessionStorage} from "./storage";
import {Mutex} from 'async-mutex';
import auth from "../../api/common-auth/auth";

const KSS_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';
const KSS_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
const TOKEN_DELAY = 60; // Seconds
const TOKEN_TTL = 55 * 60 * 1000; // milliseconds

const AuthContext = createContext(undefined);
const mutex = new Mutex();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	const [accessToken, setAccessToken] = useStateWithSessionStorage(KSS_ACCESS_TOKEN, null);
	const [refreshToken, setRefreshToken] = useStateWithSessionStorage(KSS_REFRESH_TOKEN, null);

	// --- Local state --- //
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [organisationId, setOrganisationId] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	// deviceId, alternativeDeviceId, authorities, language, userId, user_name

	// --- Refresh access token process --- //
	const refresherRef = useRef(null);
	const accessTokenRef = useRef(accessToken); // https://felixgerschau.com/react-hooks-settimeout/
	const refreshTokenRef = useRef(refreshToken);

	useEffect(() => {
		accessTokenRef.current = accessToken;
		console.debug("Auth => Access token updated…");
	}, [accessToken]);
	useEffect(() => {
		refreshTokenRef.current = refreshToken;
		console.debug("Auth => Refresh token updated…");
	}, [refreshToken]);


	useEffect(() => {
		const isAuthorized = isAccessTokenValid(accessTokenRef.current);
		setIsAuthenticated(isAuthorized);
		if (isAuthorized) {
			const tokenInfo = jwtDecode(accessTokenRef.current);
			setOrganisationId(tokenInfo.organisationId);
			setFirstName(tokenInfo.firstName);
			setLastName(tokenInfo.lastName);
		}
	}, [accessToken]);

	function returnNewRefresher() {
		return setTimeout(async function() {
			await _refreshAccessToken();
			clearTimeout(refresherRef.current);
			refresherRef.current = returnNewRefresher();
		}, TOKEN_TTL);
	}

	// Toggle refresher
	useEffect(() => {
		if (isAuthenticated) {
			refresherRef.current = returnNewRefresher();
		} else {
			clearTimeout(refresherRef.current);
		}
		return () => {
			clearTimeout(refresherRef.current);
		}
	}, [isAuthenticated]);

	function _handleNewTokens(at, rt) {
		if (isAccessTokenValid(at) && isRefreshTokenValid(rt)) {
			setAccessToken(at);
			setRefreshToken(rt);
			return at;
		}
		return null;
	}

	/**
	 * Tries to refresh the accessToken
	 * @return {Promise<null|*>} the accessToken or null in case of failure
	 * @private
	 */
	async function _refreshAccessToken() {
		console.debug("Auth => Refreshing access token using the refresh token…", refreshTokenRef.current?.slice(-5));
		if (isRefreshTokenValid(refreshTokenRef.current)) {
			const {access_token: at, refresh_token: rt} = await auth.refreshAccessToken(refreshTokenRef.current);
			return _handleNewTokens(at, rt);
		}

		// Operation failed
		console.debug("Auth => Failed to refresh the access token…");
		logout();
		return null;
	}

	/**
	 * Retrieve the access token from either the KeyValueStorage or the server.
	 * It will first try to look it up in the KeyValueStorage but if the found
	 * token is expired it will use the refresh token to refresh it from the
	 * server and re-save it.
	 * @returns {Promise<String|null>} the access token or null if none was found
	 */
	async function getAccessToken() {
		console.debug("Auth => Retrieving access token…");

		if (isAccessTokenValid(accessTokenRef.current)) {
			console.debug("Auth => Retrieved access token successfully…");
			return accessTokenRef.current;
		} else if (isRefreshTokenValid(refreshTokenRef.current)) {
			console.debug("Auth => Access token has expired…");
			return await _refreshAccessToken();
		}

		console.error("Auth => Access token could not be retrieved…");
		return null;
	}

	function getElementFromToken(element, token) {
		return jwtDecode(token)[element];
	}

	async function al(n) {
		console.debug('Auth => Trying to auto-login…');
		if (isAccessTokenValid(accessTokenRef.current)) {
			console.debug('Auth => Auto-login successful…');
			return true;
		} else {
			if (isNullOrUndefined(await _refreshAccessToken())) {
				if (n < 3) {
					console.debug(`Auth => Trying to refresh the token: (${n})…`);
					return al(n + 1);
				}
			} else {
				console.debug('Auth => Auto-login successful…');
				return true;
			}
		}

		// Autologin failed
		console.debug('Auth => Auto-login failed…');
		logout();
		return false;
	}

	/**
	 * Try to authenticate on the server.
	 * Save the access and refresh tokens on the KeyValueStorage if succeed.
	 * @returns {Promise<Boolean>} true if and only if the authentication succeed
	 */
	async function autologin() {
		if (refreshToken || refreshTokenRef.current)
			return al(0);
	}

	/**
	 * Try to authenticate on the server.
	 * Save the access and refresh tokens on the KeyValueStorage if succeed.
	 * @param {string} username the authentication username
	 * @param {string} password the authentication password
	 * @returns true if and only if the authentication succeed
	 */
	async function authenticate(username, password) {
		console.debug('Auth => Trying to login…');
		const release = await mutex.acquire();
		try {
			const {access_token: at, refresh_token: rt} = await auth.authenticate(username, password);
			if (isNullOrUndefined(_handleNewTokens(at, rt))) {
				logout();
				return false;
			}
			return true;
		} catch (e) {
			// Error occurred
			console.error('Auth => Login failed…', e);
			return false;
		} finally {
			release();
		}
	}

	/**
	 * Remove the access and refresh tokens from the KeyValueStorage.
	 * Disable the user in the database.
	 */
	function logout() {
		console.debug("Auth: logout…")
		setAccessToken(null);
		setRefreshToken(null);
		setIsAuthenticated(false);
	}

	/**
	 * Retrieve the refresh token. This function gets the refresh the token,
	 *  if the token is still valid, return it, if not, return null
	 */
	function isRefreshTokenValid(rt) {
		return Boolean(rt) && (
			jwtDecode(rt).exp > (getTimestamp() + TOKEN_DELAY)
		);
	}

	function isAccessTokenValid(at) {
		if(Boolean(at)) {
			const decodedAccessToken = jwtDecode(at);
			return (
				decodedAccessToken.authorities.includes('s20-500_admin') &&
				decodedAccessToken.exp > (getTimestamp() + TOKEN_DELAY)
			);
		} else return false;
	}

	// Return the user object and auth methods
	return {
		isAuthenticated,
		organisationId,
		firstName,
		lastName,
		// Methods
		autologin,
		authenticate,
		logout,
		getAccessToken
	};
}
