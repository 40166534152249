export class InterventionDto {
	id: number;
	vehicleId: number;
	startTime: Date;
	endTime: Date;
	operatingTime: number;

	constructor(
		id,
		vehicleId,
		startTime,
		endTime,
		operatingTime,
	) {
		this.id = id;
		this.vehicleId = vehicleId;
		this.startTime = startTime;
		this.endTime = endTime;
		this.operatingTime = operatingTime;
	}
}
