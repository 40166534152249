import {withNamespaces} from "react-i18next";
import {
	Card,
	CardActions,
	CardContent,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ContractDetailsCard from "../components/cards/contractDetailsCard";
import ContractNewCard from "../components/cards/contractNewCard";
import CustomButton from "../components/shared/buttons/button";
import {useDataManager} from "../utils/hooks/data";
import ContractAPI from "../api/S2.0-500/contracts/contractAPI";
import {useAuth} from "../utils/hooks/auth";
import {withSnackbar} from "../utils/hoc/withSnackbar";
import NoData from "../components/cards/noData";
import {HoursDto} from "../api/S2.0-500/dtos/hoursDto";
import Usage from "../constants/usage";
import Led from "../components/led/led";
import DenseTable from "../components/tables/DenseTable";
import {isNullOrUndefined} from "../utils/toolbox";
import moment from "moment";

function Contracts(props) {
	const { snackbarShowMessage } = props;
	const auth = useAuth();
	const data = useDataManager();
	const {
		vehicle,
		formulas
	} = data;

	const [showTerminated, setShowTerminated] = useState(false);
	const [reload, setReload] = useState(false);
	const [rows, setRows] = useState([]);
	const [contracts:ContractDto[], setContracts] = useState([]);
	const [contract:ContractDto, setContract] = useState(null);
	const [formula:FormulaDto, setFormula] = useState(null);

	const headers = ['status', 'name', 'hours'];
	const ignored = ['uuid'];
	const translations = {
		'status': props.t("contracts_contract_status"),
		'hours': props.t("contracts_contract_hours"),
		'name': props.t("contracts_contract_name"),
	};

	// --- Update interventions --- //
	useEffect(() => {
		if(vehicle) {
			ContractAPI.getContracts(auth, vehicle.chassisNo).then(setContracts);
		} else {
			setContracts([]);
			setContract(null);
			setRows([]);
		}
	}, [vehicle, reload]);

	// --- Contract and formula --- //
	useEffect(async () => {
		function formatToHours(time, unit) {
			return moment.duration(time, unit).format('hh:mm', {trim:false});
		}

		function filter(c: ContractDto): boolean {
			return isNullOrUndefined(c.terminationDate) !== showTerminated;
		}

		function order(c1: ContractDto, c2:ContractDto): number {
			if(c1.terminationDate && isNullOrUndefined(c2.terminationDate)) return -1;
			if(c2.terminationDate && isNullOrUndefined(c1.terminationDate)) return 1;
			if(c1.activationDate < c2.activationDate) return -1;
			if(c1.formulaId < c2.formulaId) return -1;
			return c1.uuid < c2.uuid ? -1 : c1.uuid > c2.uuid ? 1 : 0;
		}

		// Generate rows
		if(!Array.isArray(contracts) || contracts.length === 0) return;

		const newRows:ContractDto[] = [];
		for(const _contract of contracts.filter(filter).sort(order)) {
			// Get hours
			const hoursDto:HoursDto = await ContractAPI.getHours(auth, vehicle.chassisNo, _contract.uuid);
			console.log(hoursDto);
			const hours = [
				formatToHours(hoursDto.consumedHours, 'hours'),
				formatToHours(hoursDto.consumedHours + hoursDto.availableHours, 'hours')
			].join('/');
			const color = Usage.getColor(hoursDto?.usage);
			const tooltip = Usage.getDescription(hoursDto?.usage, props.t);

			// Info
			const info = _contract.terminationDate ? (
					<span className={"text-warning__italic"}>
						{`(${props.t("terminated")})`}
					</span>
				) : _contract.cancellationDate ? (
					<span className={"text-warning__italic"}>
						{`(${props.t("cancelled")})`}
					</span>
				) : null;

			// Get formula
			const formula:FormulaDto = formulas.find(f => f.id === _contract.formulaId);

			// Insert data
			newRows.push(Object.assign(
				{},
				{
					uuid: _contract.uuid,
					name: <div>{formula.name}{info}</div>,
					hours: <div>{hours}</div>,
					status: <Led color={color} tooltip={tooltip} />
				}
			));
		}

		// Update UI
		setRows(newRows);
	}, [contracts, showTerminated]);

	// Update contract
	useEffect(() => {
		if(contract) {
			setContract(contracts.find(c => c.uuid === contract.uuid));
		}
	}, [contracts]);

	// Update formula
	useEffect(() => {
		if(Array.isArray(formulas) && !isNullOrUndefined(contract)) {
			setFormula(formulas.find(f => f.id === contract.formulaId));
		}
	}, [contract, formulas]);

	// Hide the details when switching from terminated to active and vice-versa
	useEffect(() => {
		setContract(null);
	}, [showTerminated]);

	/* --- CALLBACKS --- */
	const onSuccess = () => {
		snackbarShowMessage(props.t("api_operation_succeed"));
		setReload(!reload);
	}
	const onFailure = () => {
		snackbarShowMessage(props.t("api_operation_failed"), "error");
	}

	/* --- RENDER --- */
	return vehicle ? (
		<React.Fragment>
			<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
				<CardContent className={"custom-card-content"}>
					<DenseTable
						headers={headers}
						ignored={ignored}
						translations={translations}
						items={rows}
						onRowClick={item => setContract(contracts.find(c => c.uuid === item.uuid))}
					/>
				</CardContent>
				<CardActions>
					<CustomButton
						className={"btn-card"}
						text={props.t("contracts_new_contract")}
						onClick={() => setContract(null)}
					/>
					<CustomButton
						className={"btn-card"}
						text={showTerminated ?
							props.t("contracts_actions_hide_terminated") :
							props.t("contracts_actions_show_terminated")
						}
						onClick={() => setShowTerminated(!showTerminated)}
					/>
				</CardActions>
			</Card>
			{ contract && formula ? (
				<ContractDetailsCard
					chassisNo={vehicle.chassisNo}
					contract={contract}
					formula={formula}
					onSuccess={onSuccess}
					onFailure={onFailure}
				/>
			) : (
				<ContractNewCard
					chassisNo={vehicle.chassisNo}
					contracts={contracts}
					formulas={formulas}
					onSuccess={onSuccess}
					onFailure={onFailure}
				/>
			)}
		</React.Fragment>
	) : (
		<NoData />
	);
}

export default withNamespaces()(withSnackbar(Contracts));
