import {request, requestUrlEncoded} from "../../api";
import {URL_SERVICES_S20500_CONTRACTS, URL_SERVICES_S20500_FORMULAS} from "../../../constants/urls";
import {HTTP} from "../../../constants/http";

export default {

	/**
	 * Returns all contracts.
	 * @param auth provided by useAuth or withAuth.
	 * @param chassisNo {string} the chassisNo of the vehicle.
	 * @return {Promise<Array<ContractDto>>}
	 */
	async getContracts(auth, chassisNo) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo),
			null
		);
	},

	/**
	 * Returns a contract.
	 * @param auth provided by useAuth or withAuth.
	 * @param chassisNo {string} the chassisNo of the vehicle.
	 * @param contractUUID {string} UUID of the contract.
	 * @return {Promise<ContractDto>}
	 */
	async getContract(auth, chassisNo, contractUUID) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}`,
			null
		);
	},

	async createContract(auth, chassisNo, formulaId, from, renewalType, renewalCount) {
		const payload = {
			formulaId,
			from,
			renewalType,
			renewalCount
		};
		return await requestUrlEncoded(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + '/create',
			payload
		);
	},

	async cancelContract(auth, chassisNo, contractUUID) {
		return await request(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/cancel`,
			null
		);
	},

	async extendContract(auth, chassisNo, contractUUID, amount) {
		return await requestUrlEncoded(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/extend`,
			{ amount }
		);
	},

	async renewContract(auth, chassisNo, contractUUID) {
		return await request(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/renew`,
			null
		);
	},

	async terminateContract(auth, chassisNo, contractUUID) {
		return await request(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/terminate`,
			null
		);
	},

	async getHours(auth, chassisNo, contractUUID) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_CONTRACTS(chassisNo) + `/${contractUUID}/hours`,
			null
		);
	},
}
