import React from 'react';
import {withNamespaces} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import HeaderMenuUser from './user/header-menu-user';
import './_header.scss';
import {capitalizeFirstLetter} from "../../utils/string";
import {withData} from "../../utils/hoc/withData";
import {useDataManager} from "../../utils/hooks/data";
import {makeStyles, TextField} from "@material-ui/core";
import {VEHICLE_CHASSIS_NO_LENGTH} from "../../constants/utils";
import {REGEX_S2_DOT_0_CHASSIS_NO} from "../../constants/regex";

const HeaderRight = ({children}) => (
  <div className="header__right">{children}</div>
);

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    input: {
        padding: 0,
        textAlign: 'start',
        textJustify: 'center',
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
});


function Header(props) {
    const classes = useStyles();
    const dataManager = useDataManager();

    const {
        setChassisNo,
        chassisNo,
        isValid,
    } = dataManager;

    const handleChange = (event) => {
        const chassisNo = event.target.value;
        setChassisNo(chassisNo);
    }

    return (
        <header className="header">
            <div className={"header-item-container"}>
                <div className={"header-searchbar"}>
                    <span className={"header-searchbar-label"} style={{ whiteSpace: "nowrap" }}>
                        {props.t('vehicle_chassis_no')}
                    </span>
                    <TextField
                        error={!isValid}
                        type={"text"}
                        className="form-item__input"
                        value={chassisNo}
                        placeholder={capitalizeFirstLetter(`e.g. TBS50XV33M31E0576`)}
                        onChange={handleChange}
                        inputProps={{
                            maxLength: VEHICLE_CHASSIS_NO_LENGTH,
                            pattern: `${REGEX_S2_DOT_0_CHASSIS_NO}`
                        }}
                        InputProps={{ classes }}
                    />
                </div>
            </div>
            <HeaderRight>
              <HeaderMenuUser {...props} />
            </HeaderRight>
        </header>
    );
}

export default (withRouter(withData(withNamespaces()(Header))));
