import {
	Card,
	CardActions,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead, TextField
} from "@material-ui/core";
import {CustomTableCell, CustomTableRow} from "../tables/CustomTable";
import CustomButton from "../shared/buttons/button";
import {func, object, string} from "prop-types";
import ContractAPI from "../../api/S2.0-500/contracts/contractAPI";
import {useAuth} from "../../utils/hooks/auth";
import moment from "moment";
import {isNullOrUndefined} from "../../utils/toolbox";
import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {ModalOkCancel} from "../shared/modal";
import {DATE_FORMAT} from "../../constants/utils";
import FormDatePicker from "../form/formDatePicker";
import {RenewalTypes} from "../../constants/renewalTypes";
import {CustomTooltip} from "../shared/tooltip/tooltip";

function ContractDetailsCard(props) {
	const auth = useAuth();
	const {
		chassisNo,
		contract,
		formula,
		onSuccess,
		onFailure,
	} = props;

	// Dialog
	const [openModal, setOpenModal] = useState(false);

	// Values displayed
	const [isCancellable, setIsCancellable] = useState(false);
	const [isExtendable, setIsExtendable] = useState(false);
	const [projectedExpiryDate, setProjectedExpiryDate] = useState(null);
	const [projectedNextRenewal, setProjectedNextRenewal] = useState(null);

	// Extend button
	const [newRenewalCount, setNewRenewalCount] = useState(1);
	const handleRenewalChange = (event) => {
		event.preventDefault();
		setNewRenewalCount(event.target.value);
	}

	// Components
	const [modal, setModal] = useState(null);
	const modalCancel = (
		<ModalOkCancel
			title={props.t("modal_contract_cancel_title")}
			cancelTitle={props.t("modal_abort")}
			okTitle={props.t("modal_confirm")}
			onCancel={() => closeModal()}
			onOk={() => {
				closeModal();
				cancel();
			}}
		>
			{props.t("modal_contract_cancel_desc")}
		</ModalOkCancel>
	);

	const modalTerminate = (
		<ModalOkCancel
			title={props.t("modal_contract_terminate_title")}
			cancelTitle={props.t("modal_abort")}
			okTitle={props.t("modal_confirm")}
			onCancel={() => closeModal()}
			onOk={() => {
				closeModal();
				terminate();
			}}
		>
			{props.t("modal_contract_terminate_desc")}
		</ModalOkCancel>
	);

	// Renewal count
	useEffect(() => {

		let _renewalCount = 0;
		let _renewalsAvailable = 0;
		let _isTerminated = false;
		let _isCancellable = false;
		let _isExtendable = false;
		let _projectedExpiryDate = null;
		let _projectedNextRenewal = null;

		if(contract && formula) {
			if(isNullOrUndefined(contract.terminationDate)) {
				if(isNullOrUndefined(contract.cancellationDate)) {
					// Renewal
					if(contract.renewalType === RenewalTypes.FINITE.name) {
						_renewalCount = contract.renewals.reduce((prev, curr) => prev + curr.amount, 0);
					}
					_renewalsAvailable = _renewalCount - contract.subscriptions?.length || 0;
					_isCancellable = formula.cancellable;
					_isExtendable = formula.extendable && contract.renewalType === RenewalTypes.FINITE.name;

					// Get projected expiry date.
					let willRenew;
					switch (contract.renewalType) {
						case RenewalTypes.INFINITE.name:
							_projectedExpiryDate = null;
							willRenew = !_isTerminated;
							break;
						case RenewalTypes.FINITE.name:
							const x = contract.cancellationDate ? 1 : _renewalCount;
							_projectedExpiryDate = contract.terminationDate || moment(contract.activationDate)
								.add(formula.duration * x, "day")
								.format(DATE_FORMAT)
								.toString();
							willRenew = !_isTerminated && _renewalsAvailable > 0;
							break;
						case RenewalTypes.NONE.name:
							_projectedExpiryDate = contract.terminationDate || moment(contract.activationDate)
								.add(formula.duration, "day")
								.format(DATE_FORMAT)
								.toString();
							willRenew = false;
							break;
					}

					// Get next renewal
					_projectedNextRenewal = willRenew ?
						moment(contract.activationDate)
							.add(formula.duration, "day")
							.format(DATE_FORMAT)
							.toString() : null;
				} else {
					_projectedNextRenewal = null;
					_projectedExpiryDate = moment(contract.activationDate)
						.add(formula.duration, "day")
						.format(DATE_FORMAT)
						.toString();
				}
			}
		}

		// Assign values
		setIsCancellable(_isCancellable);
		setIsExtendable(_isExtendable);
		setProjectedExpiryDate(_projectedExpiryDate);
		setProjectedNextRenewal(_projectedNextRenewal);
	}, [contract]);


	function cancel() {
		ContractAPI.cancelContract(auth, chassisNo, contract.uuid)
			.then(onSuccess)
			.catch(onFailure);
	}

	function extend() {
		ContractAPI.extendContract(auth, chassisNo, contract.uuid, newRenewalCount)
			.then(onSuccess)
			.catch(onFailure);
	}

	function terminate() {
		ContractAPI.terminateContract(auth, chassisNo, contract.uuid)
			.then(onSuccess)
			.catch(onFailure);
	}

	// UI
	function openCancelModal() {
		setOpenModal(false);
		setModal(modalCancel);
		setOpenModal(true);
	}

	function openTerminateModal() {
		setOpenModal(false);
		setModal(modalTerminate);
		setOpenModal(true);
	}

	function closeModal() {
		setOpenModal(false);
		setModal(null);
	}

	// RENDER
	return (
		<React.Fragment>
			<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
				<CardContent className={"custom-card-content"}>
					<TableContainer component={Paper} className={"custom-card-table-container"}>
						<Table stickyHeader size="small">
							<TableHead>
								<CustomTableRow>
									<CustomTableCell align="center">
										<h1>{props.t("form_contract_details_title")}</h1>
									</CustomTableCell>
								</CustomTableRow>
							</TableHead>
							<TableBody>
								<CustomTableRow>
									<CustomTableCell>
										<div className={"form-item"}>
										<span className={"form-item__label"}>
											{props.t("form_contract_details_uuid")}
										</span>
											<TextField
												className={"form-item__input"}
												value={contract.uuid}
												disabled={true}
											/>
										</div>
									</CustomTableCell>
								</CustomTableRow>
								<CustomTableRow>
									<CustomTableCell>
										<FormDatePicker
											label={props.t("form_contract_details_activation")}
											value={contract.activationDate}
											disabled={true}
										/>
									</CustomTableCell>
								</CustomTableRow>
								<CustomTableRow>
									<CustomTableCell>
										{isNullOrUndefined(contract.terminationDate) ? (
											projectedExpiryDate ? (
												<FormDatePicker
													label={props.t("form_contract_details_expire")}
													value={projectedExpiryDate}
													disabled={true}
												/>
											) : (
												<div className={"form-item"}>
												<span className={"form-item__label"}>
													{props.t("form_contract_details_expire")}
												</span>
													<TextField
														className={"form-item__input"}
														value={props.t("never")}
														disabled={true}
													/>
												</div>
											)
										) : (
											<FormDatePicker
												label={props.t("form_contract_details_termination")}
												value={contract.terminationDate}
												disabled={true}
											/>
										)}
									</CustomTableCell>
								</CustomTableRow>
								{projectedNextRenewal && (
									<CustomTableRow>
										<CustomTableCell>
											<FormDatePicker
												label={props.t("form_contract_details_next_renewal")}
												value={projectedNextRenewal}
												disabled={true}
											/>
										</CustomTableCell>
									</CustomTableRow>
								)}
								{isExtendable && (
									<CustomTableRow>
										<CustomTableCell>
											<div className={"form-item"}>
												<CustomButton
													className={"btn-card form-item__label"}
													text={props.t("form_contract_details_actions_extend")}
													onClick={extend}
													disabled={!isExtendable}
												/>
												<TextField
													className={"form-item__input"}
													type="number"
													value={newRenewalCount}
													onChange={handleRenewalChange}
													InputProps={{ inputProps: { min: 1, max: Number.MAX_SAFE_INTEGER } }}
												/>
												<CustomTooltip title={props.t("form_contract_details_actions_extend_tooltip")} />
											</div>
										</CustomTableCell>
									</CustomTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
				<CardActions>
					<div className={"flex-container"}>
						<CustomButton
							className={"btn-card"}
							text={props.t("form_contract_details_actions_cancel")}
							onClick={() => openCancelModal()}
							disabled={!isCancellable}
						/>
						<CustomButton
							className={"btn-card"}
							text={props.t("form_contract_details_actions_terminate")}
							onClick={() => openTerminateModal()}
							disabled={!isNullOrUndefined(contract.terminationDate)}
						/>
					</div>
				</CardActions>
			</Card>
			{openModal && modal}
		</React.Fragment>
	);
}

ContractDetailsCard.propTypes = {
	chassisNo: string,
	contract: object,
	formula: object,
	onSuccess: func,
	onFailure: func,
}

export default withNamespaces()(ContractDetailsCard);
