import {request, requestUrlEncoded} from "../../api";
import {URL_SERVICES_S20500_VEHICLES} from "../../../constants/urls";
import {HTTP} from "../../../constants/http";

export default {

	async addVehicle(auth, chassisNo, resetDate) {
		return await requestUrlEncoded(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_VEHICLES,
			{ chassisNo, resetDate }
			);
	},

	async resetVehicle(auth, chassisNo, resetDate) {
		return await requestUrlEncoded(
			HTTP.POST,
			auth,
			URL_SERVICES_S20500_VEHICLES + `/${chassisNo}/reset`,
			{ resetDate }
			);
	},

	async deleteVehicle(auth, chassisNo) {
		return await request(
			HTTP.DELETE,
			auth,
			URL_SERVICES_S20500_VEHICLES + `/${chassisNo}`,
			null
		);
	},

	/**
	 * Returns all vehicle.
	 * @param auth provided by useAuth or withAuth.
	 * @return {Promise<Array<VehicleDto>>}
	 */
	async getVehicles(auth) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_VEHICLES,
			null
		);
	},

	/**
	 * Returns a vehicle.
	 * @param auth provided by useAuth or withAuth.
	 * @param identifier {number|string} can be either the chassisNo or the ID of the vehicle.
	 * @return {Promise<VehicleDto>}
	 */
	async getVehicle(auth, identifier) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_VEHICLES + `/${identifier}`,
			null
		);
	},

	/**
	 * Returns the hours of a vehicle.
	 * @param auth provided by useAuth or withAuth.
	 * @param identifier {number|string} can be either the chassisNo or the ID of the vehicle.
	 * @return {Promise<HoursDto>}
	 */
	async getVehicleHours(auth, identifier) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_VEHICLES + `/${identifier}/hours`,
			null
		);
	}
}
