import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader} from "react-pro-sidebar";
import {withNamespaces} from "react-i18next";
import "./_sidebar.scss";
import CustomButton from "../shared/buttons/button";
import {modules} from "../../constants/modules";
import {Link, withRouter} from "react-router-dom";
import {Assignment, DriveEta, EmojiTransportation} from "@material-ui/icons";

function Sidebar(props) {
	const { t } = props;
	const collapsed = window.innerWidth < 760;
	return (
		<ProSidebar collapsed={collapsed} className={"sidebar"}>
			<SidebarHeader>
				<CustomButton
					className="btn-sidebar"
					text={t("app_name")}
					onClick={() => props.history.push(modules.app.redirect)}>
				</CustomButton>
			</SidebarHeader>
			<SidebarContent>
				<Menu iconShape="circle">
					<MenuItem icon={collapsed ? <Assignment className={"sidebar-icon"} /> : null}>
						{t("sidebar_contracts")}
						<Link to={modules.app.routes.contracts.path}/>
					</MenuItem>
				</Menu>
				<Menu iconShape="circle">
					<MenuItem icon={collapsed ? <DriveEta className={"sidebar-icon"}/> : null}>
						{t("sidebar_interventions")}
						<Link to={modules.app.routes.interventions.path}/>
					</MenuItem>
				</Menu>
				<Menu iconShape="circle">
					<MenuItem icon={collapsed ? <EmojiTransportation className={"sidebar-icon"} /> : null}>
						{t("sidebar_vehicles")}
						<Link to={modules.app.routes.vehicles.path}/>
					</MenuItem>
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
}

export default withRouter(withNamespaces()(Sidebar));
