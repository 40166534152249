import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from "../shared/icons/icon";

const HeaderMenuItem = ({title, iconName, onClick, value, to, children}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const arrowClass = classnames('header-menu-content__arrow', {
    'header-menu-content--open__arrow': isSubMenuOpen
  });

  const handleClick = e => {
    if (children) {
      setIsSubMenuOpen(!isSubMenuOpen);
    } else if (onClick) {
      onClick(e);
    }
  };

  return !children ? (
    <div className="header-menu-content__item" onClick={handleClick} id={value}>
      {iconName && (
        <Icon name={iconName} className="header-menu-content__item__icon" />
      )}
      {to ? (
        <div className="header-item-container">
          <Link className="header-menu-content__item__text" to={to}>
            {title}
          </Link>
        </div>
      ) : (
        title
      )}
    </div>
  ) : (
    <div className="header-menu-content__item__parent" onClick={handleClick} id={value}>
      <div className="header-menu-content__item">
        {iconName && (
          <Icon name={iconName} className="header-menu-content__item__icon" />
        )}
        {title}
        <Icon name="arrowDown" className={arrowClass} />
      </div>
      {isSubMenuOpen && <div className="header-menu-content__item__text">{children}</div>}
    </div>
  );
};

HeaderMenuItem.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string,
  to: PropTypes.string
};

export default HeaderMenuItem;
