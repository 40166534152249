export class HoursDto {
	availableHours: number;
	consumedHours: number;
	usage: string;

	constructor(
		availableHours,
		consumedHours,
		usage
	) {
		this.availableHours = availableHours;
		this.consumedHours = consumedHours;
		this.usage = usage;
	}
}
