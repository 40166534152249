import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from "../shared/icons/icon";

export default class HeaderMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({isOpen: false});
    }
  }

  handleButtonClick() {
    this.setState({isOpen: !this.state.isOpen});
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    const {
      title,
      subtitle,
      iconName,
      component,
      className,
      onClick,
      children,
      arrow = true,
      img
    } = this.props;
    const {isOpen} = this.state;

    const menuClasses = classnames(
      'header-menu',
      {'header-menu--open': isOpen},
      className
    );

    const menuButtonClasses = classnames('header-menu-button', {
      'header-menu-button--open': isOpen
    });
    const arrowClass = classnames('header-menu-button__arrow', {
      'header-menu-button--open__arrow': isOpen
    });

    const menuContentClasses = classnames('header-menu-content', {
      'header-menu-content--open': isOpen
    });

    return (
      <div className={menuClasses} onClick={onClick} ref={this.setWrapperRef}>
        <div className={menuButtonClasses} onClick={this.handleButtonClick}>
          {component ? (
            component
          ) : (
            <Fragment>
              {iconName && (
                <Icon name={iconName} className="header-menu-button__icon" />
              )}
              {title && (
                <div className="header-menu-button__text">
                  <p className="header-menu-button__text__title">{title}</p>
                  {subtitle && (
                    <p className="header-menu-button__text__subtitle">
                      {subtitle}
                    </p>
                  )}
                </div>
              )}
              {img && <div className="header-logo">{img}</div>}
              {arrow && <Icon name="arrowDown" className={arrowClass} />}
            </Fragment>
          )}
        </div>
        {children && <div className={menuContentClasses}>{children}</div>}
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconName: PropTypes.string,
  component: PropTypes.element,
  onClick: PropTypes.func
};
