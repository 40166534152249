import {TableCell, TableRow, withStyles} from "@material-ui/core";

export const CustomTableRow = withStyles((theme) => ({
	root: {
		height: 50,
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

export const CustomTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#FFCC00",
		color: theme.palette.common.black,
		borderWidth: 1,
		borderColor: theme.palette.common.black,
		fontSize: 18
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);


