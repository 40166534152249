import SignInForm from "../components/sign/signin/signin-form";
import Contracts from "../pages/contracts";
import Interventions from "../pages/interventions";
import Vehicles from "../pages/vehicles";

export const modules = {
	login: {
		redirect: '/login',
		routes: {
			login: {
				path: '/login',
				component: SignInForm
			}
		}
	},
	app: {
		redirect: '/vehicles',
		routes: {
			vehicles: {
				path: '/vehicles',
				component: props => <Vehicles {...props} />
			},
			contracts: {
				path: '/contracts',
				component: props => <Contracts {...props} />
			},
			interventions: {
				path: '/interventions',
				component: props => <Interventions {...props} />
			}
		}
	}
}
