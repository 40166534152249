import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {capitalizeFirstLetter} from '../../../utils/string';
import Icon from "../../shared/icons/icon";
import './_signin-form.scss';

const SignInFormItem = props => {
  const {iconName} = props;
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState(props.type);
  const switchShowPassword = () => {
    setType(showPassword ? 'password' : 'input');
    setShowPassword(!showPassword);
  };
  return (
    <div className="signin-form-item">
      {iconName && <Icon name={iconName} className="signin-form-item__icon" />}
      <input
        className="signin-form-item__input"
        type={type}
        name={props.name}
        placeholder={capitalizeFirstLetter(props.placeholder)}
        tabIndex={props.tabIndex}
        value={props.value}
        onChange={props.onChange}
        autoComplete={props.autocomplete}
      />
      {props.type === 'password' && (
        <Icon
          name={showPassword ? 'eyeOff' : 'eye'}
          className="signin-form-item__icon"
          onClick={switchShowPassword}
        />
      )}
    </div>
  );
};

SignInFormItem.defaultProps = {
  type: 'text'
};

SignInFormItem.propTypes = {
  iconName: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default SignInFormItem;
