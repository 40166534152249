export class VehicleDto {
	chassisNo: string;
	id: number;
	resetDate: Date;

	constructor(id, chassisNo, resetDate) {
		this.id = id;
		this.chassisNo = chassisNo;
		this.resetDate = resetDate;
	}
}
