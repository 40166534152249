/**
 * Refers to RenewalType from the library of the S20500 service.
 */
export class RenewalType {
	name: string;
	i18n: string;
	value: number;

	constructor(name, i18n, value) {
		this.name = name;
		this.i18n = i18n;
		this.value = value;
	}
}

export const RenewalTypes = {
	NONE: new RenewalType('NONE', 'renewal_type_none', 0),
	FINITE: new RenewalType('FINITE', 'renewal_type_finite', 1),
	INFINITE: new RenewalType('INFINITE', 'renewal_type_infinite', -1),
};
