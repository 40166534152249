import {useEffect, useState} from 'react';
import {isNullOrUndefined} from "../toolbox";

export const useStateWithLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(localStorage.getItem(key) || defaultValue);

    // Keep up-to-date
    useEffect(() => {
        if(isNullOrUndefined(value)) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }, [value]);
    return [value, setValue];
}

export const useStateWithSessionStorage = (key, defaultValue) => {
    const [value, setValue] = useState(sessionStorage.getItem(key) || defaultValue);

    // Keep up-to-date
    useEffect(() => {
        if(isNullOrUndefined(value)) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, value);
        }
    }, [value]);
    return [value, setValue];
}
