import qs from 'qs';
import axios from 'axios';
import {sleep} from "../../utils/toolbox";
import api from "../api";
import {URL_SERVICES_COMMON_AUTH} from "../../constants/urls";

const URL_TOKEN = URL_SERVICES_COMMON_AUTH + 'oauth/token';
const MAX_RETRY = 5;
const MAX_DELAY = 2000;

function handleError(e, url, method) {
	const error = e.response;
	if(error) {
		console.error(`Request failed with status ${error.status} {
			url : ${url}
			vrb : ${method}
			err : ${error.data.error}
			msg : ${error.data.message}
			tms : ${error.data.timestamp}
		}`);
	} else {
		console.error(e);
	}
}

async function request(body) {
	let count = 0;
	while (count < MAX_RETRY) {
		try {
			const response = await axios.post(URL_TOKEN, qs.stringify(body));
			return response.data;
		} catch (error) {
			handleError(error, URL_TOKEN, "POST");
			if ([500, 502, 503].some(code => error.message.includes(code) && count < MAX_RETRY)) {
				count++
				await sleep(MAX_DELAY)
			} else throw error
		}
	}
}

export default {
	async authenticate(username, password) {
		return request({
			client_id: api.clientId,
			grant_type: 'password',
			username: username,
			password: password
		});
	},

	async refreshAccessToken(refreshToken) {
		return request({
			client_id: api.clientId,
			grant_type: 'refresh_token',
			refresh_token: refreshToken
		});
	},
}
