import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from "../icons/icon";
import './_buttons.scss';
import {isNullOrUndefined} from "../../../utils/toolbox";

const btnTypes = ['success', 'danger', 'secondary'];
const btnSizes = ['xs', 'sm'];

const CustomButton = props => {
  const typeClass = props.buttonType && `btn-${props.buttonType}`;
  const btnClass = props.size && `btn-${props.size}`;
  const classnames = cx('btn', btnClass, typeClass, props.className);
  const iconClassName = "btn__icon" + isNullOrUndefined(props.iconSize) ? '' : '__' + props.iconSize;
  return (
    <button
      type={props.type}
      className={classnames}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.iconName && <Icon name={props.iconName} className={iconClassName} />}
      <span>{props.text}</span>
    </button>
  );
};

CustomButton.defaultProps = {
  disabled: false,
  type: 'button'
};

CustomButton.propTypes = {
  type: PropTypes.string,
  buttonType: PropTypes.oneOf(btnTypes),
  size: PropTypes.oneOf(btnSizes),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  onClick: PropTypes.func
};

export default CustomButton;
