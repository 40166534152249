const ERROR_TIMESTAMP_INVALID = "Invalid timestamp provided! Must be a valid Date object.";

/**
 * @returns the current Timestamp in seconds
 */
export function getTimestamp() {
	return Math.floor(Date.now() / 1000);
}

/**
 * @returns the current date in ISO8601 format
 */
export function getISO8601() {
	return new Date().toISOString();
}

/**
 * Returns the timestamp in milliseconds.
 * @param t timestamp to convert.
 * @returns {number} timestamp in milliseconds.
 */
export function getTimestampFromParameter(t) {
	if(t instanceof Date) return t.getTime();
	if(typeof t === "number") {
		if(isNaN(t) || t === Infinity || t === -Infinity) {
			throw Error(ERROR_TIMESTAMP_INVALID);
		} else return t;
	}
	if(typeof t === "string") {
		let t2 = Date.parse(t);
		if(isNaN(t2) || t2 === Infinity || t2 === -Infinity) {
			throw Error(ERROR_TIMESTAMP_INVALID);
		} else return t2;
	} else throw Error(ERROR_TIMESTAMP_INVALID);
}

export function getISOFromParameter(t) {
	const timestamp = getTimestampFromParameter(t);
	return (new Date(timestamp)).toISOString();
}
