import React, {useEffect} from 'react';
import {
	Card,
	CardActions,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead, TextField
} from "@material-ui/core";
import CustomButton from "../shared/buttons/button";
import {array, func, string} from "prop-types";
import ContractAPI from "../../api/S2.0-500/contracts/contractAPI";
import {useAuth} from "../../utils/hooks/auth";
import moment from "moment";
import {useState} from "react";
import {CustomTableCell, CustomTableRow} from "../tables/CustomTable";
import {withNamespaces} from "react-i18next";
import {CustomTooltip} from "../shared/tooltip/tooltip";
import {DATE_FORMAT} from "../../constants/utils";
import FormSelectInput from "../form/formSelect";
import FormDatePicker from "../form/formDatePicker";
import {isNullOrUndefined} from "../../utils/toolbox";
import FormCheckbox from "../form/formCheckbox";
import {RenewalTypes} from "../../constants/renewalTypes";

function ContractNewCard(props) {
	const auth = useAuth();
	const {
		chassisNo,
		contracts,
		formulas,
		onSuccess,
		onFailure,
	} = props;

	const [formula, setFormula] = useState(null);
	const [activation, setActivation] = useState(moment().toISOString());
	const [expiry, setExpiry] = useState(props.t('none'));
	const [renewalCount, setRenewalCount] = useState(1);
	const [renewalType, setRenewalType] = useState(null);

	function createContract() {
		if(canCreateContract()) {
			ContractAPI.createContract(
				auth,
				chassisNo,
				formula.id,
				activation,
				renewalType.name,
				renewalCount
			)
				.then(onSuccess)
				.catch(onFailure);
		}
	}

	useEffect(() => {
		let e;
		if(isNullOrUndefined(formula)) return;

		// Handle renewal type
		switch (renewalType) {
			case RenewalTypes.FINITE:
				e = moment(activation)
					.add(formula.duration * renewalCount, 'day')
					.format(DATE_FORMAT)
					.toString();
				break;
			case RenewalTypes.INFINITE:
				e = props.t("never");
				break;
			case RenewalTypes.NONE:
				e = moment(activation)
					.add(formula.duration, 'day')
					.format(DATE_FORMAT)
					.toString();
				break;
		}
		setExpiry(e);
	}, [formula, renewalCount, renewalType]);

	function canCreateContract() {
		return !(
			isNullOrUndefined(chassisNo) ||
			isNullOrUndefined(formula) ||
			isNullOrUndefined(activation) ||
			isNullOrUndefined(renewalType) || (
				renewalType.name === RenewalTypes.FINITE.name &&
				isNullOrUndefined(renewalCount)
			)
		);
	}

	const handleRenewalChange = (event) => {
		setRenewalCount(event.target.value);
	}

	// Filter to hide non-duplicable contracts
	const filteredFormulas = Array.isArray(formulas) ?
		Array.isArray(contracts) ?
			formulas.filter(f => f.duplicable || contracts.every(c => c.formulaId !== f.id || c.terminationDate))
		: formulas
	: [];

	// Render
	return (
		<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
			<CardContent className={"custom-card-content"}>
				<TableContainer component={Paper} className={"custom-card-table-container"}>
					<Table stickyHeader size="small">
						<TableHead>
							<CustomTableRow>
								<CustomTableCell align="center">
									<h1>{props.t("form_contract_new_title")}</h1>
								</CustomTableCell>
							</CustomTableRow>
						</TableHead>
						<TableBody>
							<CustomTableRow>
								<CustomTableCell>
									<FormSelectInput
										label={props.t("form_contract_new_formula_label")}
										items={filteredFormulas}
										renderFnc={item => item.name}
										sortFnc={(f1, f2) => f1.name > f2.name ? 1 : -1}
										onChange={setFormula}
									/>
								</CustomTableCell>
							</CustomTableRow>
							<CustomTableRow>
								<CustomTableCell>
									<FormDatePicker
										label={props.t("form_contract_new_activation_label")}
										value={activation}
										onChange={setActivation}
									/>
								</CustomTableCell>
							</CustomTableRow>
							<CustomTableRow>
								<CustomTableCell>
									<div className={"form-item"}>
										<span className={"form-item__label"}>
											{props.t("form_contract_new_expire_label")}
										</span>
										<TextField
											className={"form-item__input"}
											value={expiry}
											disabled={true}
										/>
									</div>
								</CustomTableCell>
							</CustomTableRow>
							<CustomTableRow>
								<CustomTableCell>
									<div className={"form-item"}>
										<span className={"form-item__label"}>
											{props.t("form_contract_new_duration_label")}
											<CustomTooltip title={props.t('form_contract_new_duration_label_tooltip')} />
										</span>
										<TextField
											className={"form-item__input"}
											value={formula && formula.duration}
											disabled={true}
										/>
									</div>
								</CustomTableCell>
							</CustomTableRow>
							<CustomTableRow>
								<CustomTableCell>
									<div className={"form-item"}>
										<span className={"form-item__label"}>
											{renewalCount > 1 ?
												props.t("form_contract_new_renewals_label") :
												props.t("form_contract_new_renewal_label")
											}
										</span>
										<FormSelectInput
											disabled={isNullOrUndefined(formula)}
											value={renewalType}
											items={[...Object.values(RenewalTypes)]}
											renderFnc={item => props.t(item.i18n)}
											sortFnc={(i1, i2) => props.t(i1.i18n) > props.t(i2.i18n) ? 1 : -1}
											onChange={setRenewalType}
										/>
										{renewalType === RenewalTypes.FINITE && (
											<TextField
												className={"form-item__input"}
												type="number"
												value={renewalCount}
												onChange={handleRenewalChange}
												InputProps={{ inputProps: { min: 1, max: Number.MAX_SAFE_INTEGER } }}
											/>
										)}
									</div>
								</CustomTableCell>
							</CustomTableRow>
							{formula && (
								<React.Fragment>
									<CustomTableRow>
										<CustomTableCell>
											<FormCheckbox
												label={props.t('form_contract_new_is_cancellable')}
												tooltip={props.t('form_contract_new_is_cancellable_tooltip')}
												value={formula.cancellable}
												disabled={true}
											/>
										</CustomTableCell>
									</CustomTableRow>
									<CustomTableRow>
										<CustomTableCell>
											<FormCheckbox
												label={props.t('form_contract_new_is_extendable')}
												tooltip={props.t('form_contract_new_is_extendable_tooltip')}
												value={formula.extendable}
												disabled={true}
											/>
										</CustomTableCell>
									</CustomTableRow>
									<CustomTableRow>
										<CustomTableCell>
											<FormCheckbox
												label={props.t('form_contract_new_is_duplicable')}
												tooltip={props.t('form_contract_new_is_duplicable_tooltip')}
												value={formula.duplicable}
												disabled={true}
											/>
										</CustomTableCell>
									</CustomTableRow>
								</React.Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
			<CardActions>
				<CustomButton
					className={"btn-card"}
					text={props.t("form_contract_new_create")}
					onClick={createContract}
					disabled={!canCreateContract()}
				/>
			</CardActions>
		</Card>
	);
}

ContractNewCard.propTypes = {
	chassisNo: string,
	contracts: array,
	formulas: array,
	onSuccess: func,
	onFailure: func,
}

export default withNamespaces()(ContractNewCard);
