import {Tooltip} from "@material-ui/core";
import {InfoRounded} from "@material-ui/icons";
import React from "react";
import "./_tooltip.scss";
import {string} from "prop-types";

export function CustomTooltip(props) {
	return (
		<Tooltip title={props.title}>
			<InfoRounded className={'tooltip'} style={{fontSize: 12}}/>
		</Tooltip>
	);
}

CustomTooltip.propTypes = {
	title: string,
}
