/* global __VERSION_INFO__, __BUILD_TIMESTAMP__ */
import React from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from 'react-i18next';
import userAgent from 'useragent.js';

import AboutSection from './about-section';
import {ModalOkCancel} from "../shared/modal";

const About = ({onClose, t}) => {
  const uaData = userAgent.analyze(navigator.userAgent);
  return (
    <ModalOkCancel
      title={t('about')}
      onCancel={onClose}
      cancelTitle={t('close')}>
      <div className="about__logo">
        <img src="/src/images/S2.0-500.png"  alt={''}/>
      </div>
      <AboutSection title={t('software')}>
        <p>
          {t('version')} : {process.env.npm_package_version}
        </p>
      </AboutSection>
        <br />
      <AboutSection title={t('system')}>
        <p>
          {t('browser')} : {uaData.browser.full}
        </p>
        <p>
          {t('system')} : {uaData.os.full}
        </p>
      </AboutSection>
    </ModalOkCancel>
  );
};

About.propTypes = {
  /** On close component function */
  onClose: PropTypes.func.isRequired
};

export default withNamespaces()(About);
