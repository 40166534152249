import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
} from "@material-ui/core";
import {array, func, object} from "prop-types";
import {withNamespaces} from "react-i18next";
import {CustomTableCell, CustomTableRow} from "./CustomTable";
import {isNullOrUndefined} from "../../utils/toolbox";
import ReactPaginate from "react-paginate";
import React, {useEffect, useState} from "react";

const ITEM_COUNT_PER_PAGE = 10; //TODO: Pagination on the server side

function DenseTable(props) {
	const {
		headers,
		ignored,
		translations,
		items,
		onRowClick
	} = props;

	// Paginate
	const [offset, setOffset] = useState(0);
	const [paginatedRows, setPaginatedRows] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	useEffect(() => {
		if(isNullOrUndefined(items)) return;
		setPaginatedRows(items.slice(offset, offset + ITEM_COUNT_PER_PAGE));
		setPageCount(Math.ceil(items.length / ITEM_COUNT_PER_PAGE));
	}, [offset, items]);

	return (
		<div>
			<TableContainer component={Paper}>
				<Table style={{tableLayout: items && items.length > 0 ? "fixed" : "auto"}} size="small" aria-label="a dense table">
					<TableHead>
						<CustomTableRow>
							{headers.map(header => isNullOrUndefined(ignored.find((v => v === header))) &&
								<CustomTableCell align={"center"}>
									{translations[header] || header}
								</CustomTableCell>
							)}
						</CustomTableRow>
					</TableHead>
					<TableBody>
						{Array.isArray(paginatedRows) && paginatedRows.length > 0 ? paginatedRows.map((item, index) => (
								<CustomTableRow key={index} onClick={() => onRowClick && onRowClick(item)}>
									{ headers.map((header, index) => {
										if(isNullOrUndefined(ignored.find((v => v === header)))) {
											return index === 0 ? (
												<CustomTableCell align="center" component="th" scope="row">
													{item[header]}
												</CustomTableCell>
											) : (
												<CustomTableCell align="center">
													{item[header]}
												</CustomTableCell>
											)
										} else {
											return null;
										}
									})}
								</CustomTableRow>
							)
						) : (
							<CustomTableRow>
								<CustomTableCell colSpan={99}>
									<div className={"flex-container flex-container__center"}>
										{props.t('nothing_found')}
									</div>
								</CustomTableCell>
							</CustomTableRow>
						)
						}
					</TableBody>
				</Table>
			</TableContainer>
			{ items && items.length > ITEM_COUNT_PER_PAGE && (
				<ReactPaginate
					pageCount={pageCount}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(data) => {
						const selected = data.selected;
						const offset = Math.ceil(selected * ITEM_COUNT_PER_PAGE);
						setOffset(offset);
					}}
					containerClassName={"pagination"}
				/>
			)}
		</div>
	)
}

DenseTable.defaultProps = {
	ignored: [],
	translations: [],
	onRowClick: null
}

DenseTable.propTypes = {
	headers: array.isRequired,
	ignored: array,
	translations: array,
	items: object.isRequired,
	onRowClick: func
};

export default withNamespaces()(DenseTable);
