import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * From: https://stackblitz.com/edit/snackbar-hoc?file=src/SnackbarHOC.js
 * @param WrappedComponent
 * @return {function(*)}
 */
export const withSnackbar = WrappedComponent => {
	return props => {
		const [open, setOpen] = useState(false);
		const [message, setMessage] = useState("I'm a custom snackbar");
		const [duration, setDuration] = useState(2000);
		const [severity, setSeverity] = useState(
			"success"
		); /** error | warning | info */

		const showMessage = (message, severity = "success", duration = 2000) => {
				setMessage(message);
				setSeverity(severity);
				setDuration(duration);
				setOpen(true);
			};

		const handleClose = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			setOpen(false);
		};

		return (
			<>
				<WrappedComponent {...props} snackbarShowMessage={showMessage} />
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					autoHideDuration={duration}
					open={open}
					onClose={handleClose}
				>
					<Alert variant="filled" onClose={handleClose} severity={severity}>
						{message}
					</Alert>
				</Snackbar>
			</>
		);
	};
};
