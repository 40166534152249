import React, {useState} from 'react';
import {withNamespaces} from 'react-i18next';
import {EMPTY_STRING} from "../../../utils/string";
import CustomButton from "../../shared/buttons/button";
import SignInFormItem from './signin-form-item';
import SignBox from "../sign-box/sign-box";
import './_signin-form.scss';
import Loading from "../../shared/loading/loading";
import {useAuth} from "../../../utils/hooks/auth";

function SignInForm(props) {

  const [username, setUsername] = useState(EMPTY_STRING);
  const [password, setPassword] = useState(EMPTY_STRING);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  function isDisabled() {
    return loading || !username || !password;
  }

  function handlePassword(e) {
    const {name, value} = e.target;
    setPassword(value);
  }

  function handleUsername(e) {
    const {name, value} = e.target;
    const trimmedValue = value.trim();
    setUsername(trimmedValue);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isDisabled()) {
      setLoading(true);
      auth.authenticate(username, password).then(success => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  }

  const {error, t} = props;
  return (
      <React.Fragment>
        <div className="sign-container">
          <SignBox>
            <form className="signin-form" onSubmit={handleSubmit}>
              <SignInFormItem
                  iconName="user"
                  name="username"
                  placeholder={t('username')}
                  tabIndex={2}
                  value={username}
                  onChange={handleUsername}
                  autocomplete="username"
              />
              <SignInFormItem
                  iconName="key"
                  name="password"
                  placeholder={t('password')}
                  type="password"
                  tabIndex={3}
                  value={password}
                  onChange={handlePassword}
                  autocomplete="current-password"
              />
              <CustomButton
                  type="submit"
                  className="signin-form__button"
                  text={t('signin')}
                  disabled={() => isDisabled()}
              />
              <div className="flex-container__center">
                {loading && <Loading />}
                {error && <span>{t('signin.error')}</span>}
              </div>
            </form>
          </SignBox>
        </div>
      </React.Fragment>
    );
}

export default withNamespaces()(SignInForm);
