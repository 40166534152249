import React from 'react';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {I18nextProvider, reactI18nextModule} from 'react-i18next';
import i18n from 'i18next';

import {configureI18n, configureMoment, DEFAULT_LANGUAGE} from './config';

const I18nManager = ({lang, children}) => {
  const locale = lang ? lang : DEFAULT_LANGUAGE;

  // Configure i18n translations
  const i18nConfig = configureI18n(locale);
  i18n
      .use(I18nextBrowserLanguageDetector)
      .use(reactI18nextModule)
      .init(i18nConfig);

  // Configure i18n Date format (Global Moment instance config)
  configureMoment(locale);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default I18nManager;
