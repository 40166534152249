import axios from 'axios';
import {stringify} from 'qs';
import {isNullOrUndefined} from "../utils/toolbox";

export default {
	clientId: 'boschung-services',
};

function handleError(e, url, method) {
	const error = e.response;
	if(error) {
		console.error(`Request failed with status ${error.status} {
			url : ${url}
			vrb : ${method}
			err : ${error.data.error}
			msg : ${error.data.message}
			tms : ${error.data.timestamp}
		}`);
	} else {
		console.error(e);
	}
}

/**
 * @throws errors if request failed.
 * @param {*} method
 * @param {*} auth
 * @param {*} url
 * @param {*} data
 */
export async function request(method, auth, url, data) {
	try {
		if(!auth.isAuthenticated) throw new Error("Authenticated Entity required to call this resource");
		const token = await auth.getAccessToken();
		if (isNullOrUndefined(token)) return null;
		const options = { method, data, headers: { authorization: 'Bearer ' + token } };
		const response = await axios(url, options);
		return response.data || true;
	} catch (e) {
		handleError(e, url, method);
		throw e;
	}
}

/**
 * @throws errors if request failed.
 * @param {*} method
 * @param {*} auth
 * @param {*} url
 * @param {*} data
 */
export async function requestUrlEncoded(method, auth, url, data) {
	try {
		const token = await auth.getAccessToken();
		if (isNullOrUndefined(token)) return null;
		const options = { method, data:stringify(data), headers: {
				authorization: 'Bearer ' + token,
				'Content-Type': 'application/x-www-form-urlencoded'
		}};
		const response = await axios(url, options);
		return response.data || true;
	} catch (e) {
		handleError(e, url, method);
		throw e;
	}
}
