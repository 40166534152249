import {useAuth} from "../utils/hooks/auth";
import {useDataManager} from "../utils/hooks/data";
import React, {useEffect, useState} from "react";
import InterventionAPI from "../api/S2.0-500/interventions/interventionAPI";
import VehicleAPI from "../api/S2.0-500/vehicles/vehicleAPI";
import {withNamespaces} from "react-i18next";
import {InterventionDto} from "../api/S2.0-500/dtos/interventionDto";
import {Card, CardActions, CardContent} from "@material-ui/core";
import DenseTable from "../components/tables/DenseTable";
import moment from "moment";
import NoData from "../components/cards/noData";
import {HoursDto} from "../api/S2.0-500/dtos/hoursDto";
import Loading from "../components/shared/loading/loading";

function Interventions(props) {
	const auth = useAuth();
	const data = useDataManager();
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [hours, setHours] = useState(null);
	const { vehicle } = data;

	const headers = Object.keys(new InterventionDto());
	const ignored = ['id', 'vehicleId'];
	const translations = {
		'startTime': props.t("intervention_start_time"),
		'endTime': props.t("intervention_end_time"),
		'operatingTime': props.t("interventions_hours_worked")
	};
	const [interventions, setInterventions] = useState([]);

	// --- Update hours --- //
	useEffect(() => {
		if(vehicle) {
			setLoading(true);
			InterventionAPI.getInterventions(auth, vehicle.chassisNo).then(setInterventions);
			VehicleAPI.getVehicleHours(auth, vehicle.chassisNo).then(setHours);
		} else {
			setInterventions([]);
			setRows([]);
			setHours(null);
		}
	}, [auth, vehicle]);

	function updateRows() {
		if(!Array.isArray(interventions) || interventions.length === 0) return;

		// Validate vehicles
		const newRows = interventions.map(intervention => {
			return Object.assign(
				{},
				intervention,
				{ operatingTime : formatToHours(intervention.operatingTime, "seconds")}
			);
		});

		// Sort by chassisNo
		const sortedRows = newRows.sort((a, b) => {
			if(moment(a.startTime).isSame(b.startTime)) {
				if(moment(a.endTime).isSame(b.endTime)) return a.id - b.id;
				return moment(a.endTime).isAfter(b.endTime) ? -1 : 1;
			} else {
				return moment(a.startTime).isAfter(b.startTime) ? -1 : 1;
			}
		})

		// Update
		setRows(sortedRows);
	}

	function formatToHours(time, unit) {
		return moment.duration(time, unit).format('hh:mm',  {trim: false});
	}

	// Update data when fetch is finished
	useEffect(() => {
		setLoading(false);
		updateRows();
	}, [interventions]);

	return vehicle ? (
		<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
			<CardContent className={"custom-card-content"}>
				{ hours && hours.usage && (
					<div style={{marginBottom:12}}>
						<DenseTable
							headers={Object.keys(new HoursDto())}
							ignored={['usage']}
							translations={{
								'availableHours': props.t('interventions_hours_available'),
								'consumedHours': props.t('interventions_hours_consumed'),
							}}
							items={[{
								'availableHours': <p style={{color: hours.availableHours < 0 ? "#FF0000" : "#00AA00"}}>{formatToHours(hours.availableHours, "hours")}</p>,
								'consumedHours': formatToHours(hours.consumedHours, "hours"),
							}]}
						/>
					</div>
				)}
				{ rows.length > 0 ? (
					<React.Fragment>
						<DenseTable
							headers={headers}
							ignored={ignored}
							translations={translations}
							items={rows}
						/>
					</React.Fragment>
				) : (loading && <Loading />)}
			</CardContent>
		</Card>
	) : (
		<NoData />
	);
}

export default withNamespaces()(Interventions);
