import {request} from "../../api";
import {URL_SERVICES_S20500_INTERVENTIONS} from "../../../constants/urls";
import {HTTP} from "../../../constants/http";

export default {

	/**
	 * Returns all interventions.
	 * @param auth provided by useAuth or withAuth.
	 * @param chassisNo {string} the chassisNo of the vehicle.
	 * @return {Promise<Array<InterventionDto>>}
	 */
	async getInterventions(auth, chassisNo) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_INTERVENTIONS(chassisNo),
			null
		);
	},

	/**
	 * Returns a intervention.
	 * @param auth provided by useAuth or withAuth.
	 * @param chassisNo {string} the chassisNo of the vehicle.
	 * @param interventionId {number} of the intervention.
	 * @return {Promise<InterventionDto>}
	 */
	async getIntervention(auth, chassisNo, interventionId) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_INTERVENTIONS(chassisNo) + `/${interventionId}`,
			null
		);
	},

	async getCurrentIntervention(auth, chassisNo) {
		return await request(
			HTTP.GET,
			auth,
			URL_SERVICES_S20500_INTERVENTIONS(chassisNo) + '/current',
			null
		);
	},
}
