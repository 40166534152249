import {Lens} from "@material-ui/icons";
import React from "react";
import {makeStyles, Tooltip} from "@material-ui/core";
import {string} from "prop-types";

const useStyles = makeStyles({
	icon: {
		color: 'transparent',
		borderRadius: 25,
		border: '1px solid #ABABAB !important',
		margin: 0,
		padding: 0,
		boxShadow: '0 3px 5px 2px rgba(200, 200, 200, .3)',
	},
});

function Led(props) {
	const classes = useStyles();
	return (
		<Tooltip title={props.tooltip}>
			<Lens
				classes={{ root: classes.icon }}
				style={{ background: props.color }}
			/>
		</Tooltip>
	);
}

Led.propTypes = {
	tooltip: string,
	color: string
}

export default Led;
