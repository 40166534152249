import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import icons from './config';

const Icon = props => {
  const {name, className, onClick} = props;
  let icon = <React.Fragment />;
  const iconClassnames = cx('icon', className);
  switch (name) {
    default:
      icon = (
        <svg
          viewBox={icons[name].boxsize}
          className={iconClassnames}
          onClick={onClick}>
          <path d={icons[name].path} />
        </svg>
      );
  }

  return icon;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Icon;
