import React from 'react';
import "./_sign-box.scss";

const SignBox = ({children}) => {
  return (
    <div className="sign-box">
      <div className="sign-box__header">
        <img
          src={process.env.PUBLIC_URL + '/S2.0-500.png'}
          className="sign-box__header__logo"
        />
      </div>
      {children}
    </div>
  );
};

export default SignBox;
