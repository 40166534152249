import React, {Component} from 'react';
import HeaderMenu from '../_header-menu';
import HeaderMenuItem from '../_header-menu-item';
import { EMPTY_STRING } from "../../../utils/string";
import About from "../../about/about";

class HeaderMenuUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutOpen: false,
      languageOpen: false
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleAbout = this.handleAbout.bind(this);
    this.onChangeLangClick = this.onChangeLangClick.bind(this);
  }

  onChangeLangClick(e) {
    const {id} = e.target;
    this.props.changeLangRequest(id);
  }

  handleLogout() {
    this.props.history.push('/');
    this.props.auth.logout();
  }

  handleAbout() {
    this.setState({aboutOpen: !this.state.aboutOpen});
  }

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        <HeaderMenu
          iconName="account"
          className={this.props.highlight ? 'highlightedMenu' : ''}
          title={EMPTY_STRING}
          subtitle={EMPTY_STRING}>
          {/* TODO: Implement User profile button to show the user page */}
          <HeaderMenuItem
            iconName="about"
            title={t('about')}
            onClick={this.handleAbout}
          />
          <HeaderMenuItem
            iconName="logout"
            title={t('logout')}
            onClick={this.handleLogout}
          />
        </HeaderMenu>
        {this.state.aboutOpen && <About onClose={this.handleAbout} />}
      </React.Fragment>
    );
  }
}

export default (HeaderMenuUser);
