import React from 'react';
import PropTypes from 'prop-types';

const AboutSection = ({title, children}) => {
  return (
    <div className="about__section">
      <h3 className="about__section__title">{title}</h3>
      {children}
    </div>
  );
};

AboutSection.propTypes = {
  /** Section title */
  title: PropTypes.string.isRequired,
  /** Section child */
  children: PropTypes.node.isRequired
};

export default AboutSection;
