import {REGEX_S2_DOT_0_CHASSIS_NO} from "./regex";

export const VEHICLE_CHASSIS_NO_LENGTH = 17;
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export function isChassisNoValid(no) {
	let m;
	let isValid = false;
	while ((m = REGEX_S2_DOT_0_CHASSIS_NO.exec(no)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === REGEX_S2_DOT_0_CHASSIS_NO.lastIndex) {
			REGEX_S2_DOT_0_CHASSIS_NO.lastIndex++;
		}
		// The result can be accessed through the `m`-variable.
		m.forEach((match, groupIndex) => {
			isValid = true;
		});
	}
	return isValid;
}
