import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import './_modal.scss';

import CustomButton from "../buttons/button";
import {Modal, ModalContent, ModalFooter} from '.';

const ModalOkCancel = ({
  title,
  onCancel,
  cancelTitle,
  onOk,
  okTitle,
  children
}) => (
  <Modal title={title}>
    <ModalContent>{children}</ModalContent>
    <ModalFooter>
      {onCancel && (
          <CustomButton className={"btn-card"} buttonType="secondary" text={cancelTitle} onClick={onCancel} />
      )}
      {onOk && <CustomButton className={"btn-card"} text={okTitle} onClick={onOk} />}
    </ModalFooter>
  </Modal>
);

ModalOkCancel.propTypes = {
  /** Modal title */
  title: PropTypes.string.isRequired,
  /** Modal content */
  children: PropTypes.node,
  /** Cancel button function */
  onCancel: PropTypes.func,
  /** Cancel button title */
  cancelTitle: requiredIf(PropTypes.string, props => props.onCancel),
  /** OK button function */
  onOk: PropTypes.func,
  /** OK button title */
  okTitle: requiredIf(PropTypes.string, props => props.onOk)
};

export default ModalOkCancel;
