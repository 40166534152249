import React, {useEffect} from 'react';
import {
	Card,
	CardActions,
	CardContent,
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead, TextField
} from "@material-ui/core";
import {KeyboardDatePicker} from '@material-ui/pickers';

import CustomButton from "../shared/buttons/button";
import {array, func} from "prop-types";
import VehicleAPI from "../../api/S2.0-500/vehicles/vehicleAPI";
import {useAuth} from "../../utils/hooks/auth";
import moment from "moment";
import {useState} from "react";
import {CustomTableCell, CustomTableRow} from "../tables/CustomTable";
import {withNamespaces} from "react-i18next";
import {DATE_FORMAT, isChassisNoValid} from "../../constants/utils";
import {REGEX_S2_DOT_0_CHASSIS_NO} from "../../constants/regex";
import {isNullOrUndefined} from "../../utils/toolbox";

function VehicleNewCard(props) {
	const auth = useAuth();
	const {
		vehicles,
		onSuccess,
		onFailure,
	} = props;

	const [chassisNo, setChassisNo] = useState(null);
	const [enableDate, setEnableDate] = useState(false);
	const [resetDate, setResetDate] = useState(null);

	function createVehicle() {
		VehicleAPI.addVehicle(
			auth,
			chassisNo,
			enableDate ? resetDate : null
		)
			.then(onSuccess)
			.catch(onFailure);
	}

	const handleChassisNoChange = (event) => {
		setChassisNo(event.target.value);
	};

	const handleResetDateChange = (date) => {
		setResetDate(moment(date).toISOString());
	};

	function isChassisNoUsed(no) {
		return Array.isArray(vehicles) && !isNullOrUndefined(vehicles.find(v => v.chassisNo === no));
	}

	const [isUsed, setIsUsed] = useState(false);
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		setIsUsed(isChassisNoUsed(chassisNo));
		setIsValid(isChassisNoValid(chassisNo));
	}, [chassisNo, vehicles]);

	function getHelperText() {
		return isUsed ?
			props.t("form_vehicle_new_chassis_no_label_helper_duplicate") :
			!isValid ?
				props.t("form_vehicle_new_chassis_no_label_helper_invalid_format") :
				'';
	}

	return (
		<Card className={"custom-card"} style={{backgroundColor: "#333333"}}>
			<CardContent className={"custom-card-content"}>
				<TableContainer component={Paper} className={"custom-card-table-container"}>
					<Table stickyHeader size="small">
						<TableHead>
							<CustomTableRow>
								<CustomTableCell align="center">
									<h1>{props.t("form_vehicle_new_title")}</h1>
								</CustomTableCell>
							</CustomTableRow>
						</TableHead>
						<TableBody>
							<CustomTableRow>
								<CustomTableCell>
									<div className={"form-item"}>
										<span className={"form-item__label"}>
											{props.t("form_vehicle_new_chassis_no_label")}
										</span>
										<TextField
											error={!isValid || isUsed}
											className={"form-item__input"}
											value={chassisNo}
											onChange={handleChassisNoChange}
											inputProps={{pattern: `${REGEX_S2_DOT_0_CHASSIS_NO}`, maxLength:17}}
											helperText={(!isValid || isUsed) && getHelperText()}
										/>
									</div>
								</CustomTableCell>
							</CustomTableRow>
							<CustomTableRow>
								<CustomTableCell>
									<div className={"form-item"}>
										<span className={"form-item__label"}>
											{props.t("form_vehicle_new_reset_date_label")}
										</span>
										<Checkbox onClick={() => setEnableDate(!enableDate)} style={{
											color: "#ffcc00"
										}}/>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											className={"form-item__input"}
											margin="normal"
											id="date-picker-dialog"
											format={DATE_FORMAT}
											value={resetDate}
											onChange={handleResetDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											disabled={!enableDate}
										/>
									</div>
								</CustomTableCell>
							</CustomTableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
			<CardActions>
				<CustomButton
					className={"btn-card"}
					text={props.t("form_vehicle_new_create")}
					onClick={createVehicle}
				/>
			</CardActions>
		</Card>
	);
}

VehicleNewCard.propTypes = {
	vehicles: array,
	onSuccess: func,
	onFailure: func,
}

export default withNamespaces()(VehicleNewCard);
